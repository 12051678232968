import { StepSyncProgress, IStepSyncProgressOptions, IStepSyncProgressJSON } from 'o365.pwa.modules.client.steps.StepSyncProgress.ts';

export interface IStoredProcedureProgressJSON extends IStepSyncProgressJSON {
    procedureHasStarted: boolean;
    procedureHasErrors: boolean;
    procedureHasCompleted: boolean;
}

export interface IStoredProcedureProgressOptions extends IStepSyncProgressOptions {
    procedureHasStarted?: boolean;
    procedureHasErrors?: boolean;
    procedureHasCompleted?: boolean;
}

export class StoredProcedureProgress extends StepSyncProgress {
    private _procedureHasErrors: boolean = false;

    public procedureHasStarted: boolean = false;
    public procedureHasCompleted: boolean = false;

    public set procedureHasErrors(newValue: boolean) {
        this._procedureHasErrors = newValue;
    }

    public get procedureHasErrors(): boolean {
        return this.hasError || this._procedureHasErrors;
    }

    get progressStatusPending(): number {
        if (this.hasError) {
            return 0;
        }

        return this.procedureHasStarted ? 100 : 0;
    }

    get progressStatusSuccess(): number {
        if (this.hasError) {
            return 0;
        }

        return this.procedureHasCompleted ? 100 : 0;
    }

    get progressStatusError(): number {
        if (this.hasError) {
            return 100;
        }

        return this.procedureHasErrors ? 100 : 0;
    }
    
    constructor(options: IStoredProcedureProgressOptions) {
        super(options);

        options.procedureHasStarted && (this.procedureHasStarted = options.procedureHasStarted);
        options.procedureHasErrors && (this.procedureHasErrors = options.procedureHasErrors);
        options.procedureHasCompleted && (this.procedureHasCompleted = options.procedureHasCompleted);
    }

    public toJSON(): IStoredProcedureProgressJSON {
        return Object.assign(super.toJSON(), this);
    }
}
